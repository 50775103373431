import "../styles/styles.scss";
import { debounce } from "./utils.js";
/**
 * @param {Object} options Параметры
 * @params options.root Родительский элемент слайдера
 * @params options.type тип слайдера, значения: [Default: fade, gallery] 
 * @params options.startSlide Номер слайда, с которого начинается показ
 * @params options.controls Включить кнопки переключения Default true
 * @params options.pagination Включить пагинацию. Default true
 * @params options.loop Включить бесконечный режим переключения
 * @params options.autoplay Автоперелистывание
 * @params options.speed Скорость анимации
 * @params options.delay Задержка перед переключением слайда
 * @params options.thumbnails Включить обложки. Включается вместо пагинации
 * @returns Экземпляр слайдера
 */
export default function Slider(options) {
  "use strict";
  if (!options?.root) {
    return;
  }

  options = options || {};
  const root = options.root;
  const type = options.type || "fade";
  const isLazy = options.lazy ?? true
  let activeSlideIndex = options.startSlide || 0;
  let controlsEnabled = options.controls ?? true;
  let thumbnailsEnabled = options.thumbnails || false;
  let thumbLink = options.thumbLink || false; 
  let paginationEnabled = options.pagination ?? true;
  let loop = options.loop;
  let autoplay = options.autoplay;
  let speed = options.speed ?? 200;
  let delay = options.delay ?? 1000;
  let loopInterval = null;

  let lockSlider = true;

  if (thumbnailsEnabled) {
    paginationEnabled = false;
  }

  let carousel = [];

  let slides = root.querySelectorAll(".slider__slide");
  const wrapSlides = root.querySelector(".slider__slides");

  if (!(slides.length && wrapSlides)) return;

  let buttons = {
    root: root.querySelector(".slider__buttons"),
    prev: root.querySelector(".slider__button--prev"),
    next: root.querySelector(".slider__button--next"),
  };

  let thumbnails = {
    root: root.querySelector(".slider__thumbnails"),
    inner: root.querySelector(".slider__thumbnails-inner"),
    items: [],
  };

  let pagination = {
    root: root.querySelector(".slider__pagination"),
    items: [],
  };

  const emitter = {
    listeners: {},
    on(name, fn) {
      !this.listeners[name] && (this.listeners[name] = []);
      this.listeners[name].push(fn);
    },
    off(name, fn) {
      this.listeners[name] = this.listeners[name].filter(
        (listenerFn) => listenerFn !== fn
      );
    },
    emit(name) {
      const listeners = this.listeners[name];
      listeners &&
        listeners.forEach((fn) =>
          fn.call(null, Array.prototype.slice.call(arguments, 1))
        );
    },
  };
  root.setAttribute("data-slider-type", type);
  const gotoDebounced = debounce(goto, speed);
  const handleResizeDebounced = debounce(handleResize, 100);

  function setup() {
    if (!controlsEnabled || slides.length < 2) {
      hide(buttons.root);
    } else {
      show(buttons.root);
    }
    if (!paginationEnabled || slides.length < 2) {
      hide(pagination.root);
    } else {
      show(pagination.root);
      createPaginationItems();
    }
    if (thumbnailsEnabled && slides.length > 1) {
      createThumbnails();
    }
    gotoDebounced(activeSlideIndex);
    setTimeout(() => {
      document.body.style.setProperty(
        "--slider-transition-duration",
        `${speed}ms`
      );
      startLoop();
    }, 0);

    bind();
  }

  function bind() {
    root.addEventListener("click", handleClicks);
    root.addEventListener("transitionend", handleTransitionEnd);
    document.addEventListener("keydown", handleKeydown);
    window.addEventListener("resize", handleResizeDebounced);
    if (thumbLink) thumbnails.root?.addEventListener("click", handleStopThumbLink);
    document.addEventListener("pointerdown", handleSwipeSlide);
  }

  function unbind() {
    root.removeAttribute("data-slider-type");
    root.removeEventListener("click", handleClicks);
    root.removeEventListener("transitionend", handleTransitionEnd);
    document.removeEventListener("keydown", handleKeydown);
    window.removeEventListener("resize", handleResizeDebounced);
    if (thumbLink) thumbnails.root?.removeEventListener("click", handleStopThumbLink);
    document.removeEventListener("pointerdown", handleSwipeSlide);
  }

  function translate(el, dist) {
    const style = el && el.style;

    if (!style) return;

    style.webkitTransform = "translate(" + dist + "px,0)" + "translateZ(0)";
    style.msTransform =
      style.MozTransform =
      style.OTransform =
        "translateX(" + dist + "px)";
  }

  function createPaginationItems() {
    const fragment = document.createDocumentFragment();
    let paginationAmount = slides.length;
    if (type === "gallery") {
      const slideWidth = slides[0].offsetWidth;
      const rootWidth = root.offsetWidth;
      paginationAmount = Math.ceil((slideWidth * slides.length) / rootWidth);
    }
    for (let i = 0; i < paginationAmount; i++) {
      const bullet = document.createElement("div");
      bullet.innerHTML = `
        <li class="slider__pagination-item">
          <span class="slider__pagination-bullet" aria-label="Go to slide ${i + 1}">
            Dot slide
          </span>
        </li>
      `;
      fragment.append(bullet.children[0]);
    }
    paginationAmount > 1 && pagination.root.append(...fragment.children);
    paginationAmount > 1 && pagination.items.push(...pagination.root.children);
  }

  function createThumbnails() {
    const fragment = document.createDocumentFragment();
    const getThumb = (link, isVideo) => {
      const videoClass = isVideo ? " video-item" : "";
      if (thumbLink)
        return `
        <a href="${link}" class="slider__thumbnails-item${videoClass}">
          <img width="47" height="32" src="${link}" alt />
        </a>`;
      return `
        <div class="slider__thumbnails-item${videoClass}">
          <img width="47" height="32" src="${link}" alt />
        </div>`
    }
    for (let i = 0; i < slides.length; i++) {
      const slideIframe = slides[i].querySelector("iframe");
      const isVideo = !!slideIframe;

      const slideImg = slides[i].querySelector("[data-thumb]");
      const thumbnailLink = slideImg.dataset.thumb;
      const bullet = document.createElement("div");
      bullet.innerHTML = getThumb(thumbnailLink, isVideo);
      carousel.push({
        isVideo: !!slideIframe,
        src: slideImg.dataset.src,
        thumb: thumbnailLink,
      });
      fragment.append(bullet.children[0]);
    }
    thumbnails.inner?.append(...fragment.children);
    thumbnails.items.push(...(thumbnails.inner?.children || []));
  }

  function handlePaginationItems() {
    if (!paginationEnabled || !lockSlider) return;
      const step = pagination.root.offsetWidth / 5;
      const offset = step * 2 - step * activeSlideIndex;
      pagination.items.forEach((bullet) => {
        bullet.removeAttribute("data-offset");
      });
      for (let i = activeSlideIndex; i <= activeSlideIndex + 4; i++) {
        let n = handleIndex(i - 2);
        pagination.items[n]?.setAttribute(
          "data-offset",
          n - activeSlideIndex + 2
        );
      }
      pagination.items.forEach((bullet) => {
        translate(bullet, offset, speed);
      });
  }

  function handleSliderItems() {
    if (!wrapSlides) return;
    const width = wrapSlides.offsetWidth;
    if (!lockSlider) return
    if (type === "fade")
      slides.forEach((slide, index) => {
        slide.classList.remove("slider__slide--active");
        index === activeSlideIndex && slide.classList.add("slider__slide--active"), translate(slide, -width * index);
      });
    if (type === "gallery") {
      const slideWidth = root.querySelector(".slider__slide").offsetWidth;

      const slidesAmount = Math.round(width / slideWidth);
      const slidesAmountNext = [...slides].slice(activeSlideIndex * slidesAmount, (activeSlideIndex + 1) * slidesAmount).length;
      const translateX = width * (activeSlideIndex - 1) + slidesAmountNext * slideWidth;

      translate(wrapSlides, -translateX);
    }
  }

  function handleThumbnailsItems() {
    if (!thumbnailsEnabled || slides.length === 1) {
      return;
    }
    const widthOuter = thumbnails.root?.offsetWidth || 0;
    const widthInner = thumbnails.inner?.offsetWidth || 0;
    if (widthInner > widthOuter) {
      const step = (widthInner - widthOuter) / (thumbnails.items.length - 1);
      let offset = -step * activeSlideIndex;
      thumbnails.items.forEach((thumb) => {
        translate(thumb, offset, speed);
      });
    } else {
      let offset = (widthOuter - widthInner) / 2;
      thumbnails.items.forEach((thumb) => {
        translate(thumb, offset, speed);
      });
    }

    thumbnails.items.forEach((thumb) => {
      thumb.classList.remove("js-active");
    });

    thumbnails.items[activeSlideIndex]?.classList.add("js-active");
  }

  function handleButtons() {
    if (loop) {
      return;
    }

    activeSlideIndex === 0 ? hide(buttons.prev) : show(buttons.prev);
    activeSlideIndex === slides.length - 1
      ? hide(buttons.next)
      : show(buttons.next);
  }

  function handleIndex(slideIndex) {
    if (loop) {
      return (slides.length + (slideIndex % slides.length)) % slides.length;
    } else {
      let paginationAmount = slides.length;
      
      if (type === "gallery") {
        const slideWidth = slides[0].offsetWidth;
        const rootWidth = root.offsetWidth;
        paginationAmount = Math.ceil((slideWidth * slides.length) / rootWidth);
      }
      return Math.min(paginationAmount - 1, Math.max(slideIndex, 0));
    }
  }

  function handleClicks(event) {
    if (event.target.closest(".slider__buttons")) {
      handleControlButtonClick(event);
    }
    if (event.target.closest(".slider__pagination")) {
      handlePaginationBulletClick(event);
    }
    if (event.target.closest(".slider__thumbnails")) {
      handleThumbnailsClick(event);
    }
  }

  function handleControlButtonClick({ target }) {
    const nextBtn = target.closest(".slider__button--next"),
      prevBtn = target.closest(".slider__button--prev");
    if (target.classList.contains("slider__button--next") || nextBtn) {
      next();
    } else if (target.classList.contains("slider__button--prev") || prevBtn) {
      prev();
    }
  }

  function handlePaginationBulletClick({ target }) {
    const item = target.closest(".slider__pagination-item") || target;
    const index = pagination.items.indexOf(item);
    if (index >= 0) {
      goto(index);
    }
  }

  function handleThumbnailsClick(event) {
    const item =
      event.target.closest(".slider__thumbnails-item") || event.target;
    const index = thumbnails.items?.indexOf(item);
    if (index >= 0) {
      goto(index);
    }
  }

  function handleKeydown(event) {
    if (event.key === "ArrowRight") {
      next();
    } else if (event.key === "ArrowLeft") {
      prev();
    }
  }

  function handleResize() {
    handleSliderItems();
    handlePaginationItems();
    handleThumbnailsItems();
  }

  function handleLazyLoading() {
    const slide = slides[activeSlideIndex];
    const img = slide?.querySelector("img");
    if (img) img.src = img.dataset.src;
  }

  function handleTransitionEnd(event) {
    const check = event.target.classList.contains("slider__pagination-item");
    if (check || !check && autoplay) {
      startLoop();
    }
  }

  function handleSwipeSlide(event) {
    const slide = event.target.closest(".slider__slide");
    if (!slide || !lockSlider) {
      document.onmousemove = (evt) => {
        if (slide) evt.preventDefault();
      };
      document.onclick = (evt) => {
        if (slide) evt.preventDefault();
      };
      return;
    }

    document.onclick = null;

    document.onmousemove = null;
    document.ontouchmove = null;

    document.onpointerup = null;
    document.ontouchend = null;

    const slideWidth = slide.offsetWidth;
    const slidesTranslate = +wrapSlides.style.transform?.split("translate(")[1]?.split(",")[0].replace("px", "");
    
    let startPosition;
    const elTransition = type === "gallery" ? wrapSlides : slide

    startPosition = {
      x: event.screenX,
      y: event.screenY,
    }; 

    let nextSlide = slides[0];

    document.body.onscroll = (evt) => {
      evt.preventDefault();
    }
     
    document.onmousemove = startDragSlide;
    document.ontouchmove = startDragSlide;

    document.onpointerup = endDragSlide;
    document.ontouchend = endDragSlide;
    
    if (lockSlider) lockSlider = false;

    function setNextSlide(isAdd = true, opacity = 0) {
      nextSlide?.classList[isAdd ? "add" : "remove"]("slider__slide--next");
      slide.style.zIndex = isAdd ? 10 : null;
      slide.style.opacity = opacity;
    }

    function startDragSlide(evt) {
      if (evt.type === "mousemove") evt.preventDefault();
      
      let { screenX } = evt;
      // получение данных с touchPad
      screenX = screenX ?? evt.changedTouches[0].screenX;

      const distance = startPosition.x - screenX;
      elTransition.style.transition = "0s";

      if (type === "gallery") {
        if (pagination.items.length) translate(wrapSlides, slidesTranslate + distance * -1);
      } else {
        setNextSlide(false);

        nextSlide = slides[handleIndex(distance > 0 ? activeSlideIndex + 1 : activeSlideIndex - 1)];
        const opacity = Math.abs(1 - Math.abs(distance) / slideWidth);

        setNextSlide(true, opacity);
      }
    };

    function endDragSlide(evt) {
      console.log("endDragSlide");
      document.onmousemove = null;
      document.ontouchmove = null;

      document.onpointerup = null;
      document.ontouchend = null;

      // document.onmouseup = null;
      if (evt.type === "pointerup") evt.preventDefault();
      lockSlider = true;

      let { screenX, screenY } = evt;
      // получение данных с touchPad
      screenX = screenX ?? evt.changedTouches[0].screenX;
      screenY = screenY ?? evt.changedTouches[0].screenY;

      const distance = {
        x: startPosition.x - screenX,
        y: startPosition.y - screenY,
      };
      console.log("isSetStopLink");
      const isSetStopLink = Math.abs(distance.x) < 2 && Math.abs(distance.y) < 2;
      if (isSetStopLink) return;
      console.log("isSetStopLink_2");

      // нужно именно так чтобы на IOS работало нормально
      document.onclick = (e) => {
        e.preventDefault();
        document.onclick = null;
      };
      elTransition.style.transition = null;

      if (type === "fade") {
        setNextSlide();
        setTimeout(() => {
          lockSlider = true;
          slide.style.opacity = null;
          setNextSlide(false, null);
        }, speed);
      } 
      // я не знаю почему, но через gotoDebounced вылезают баги с type = "gallery"
      if (distance.x > 0) {
        console.log("next");
        goto(activeSlideIndex + 1);
      } else {
        console.log("prev");
        goto(activeSlideIndex - 1);
      }
      if (type === "fade") lockSlider = false;
    }
  }

  function handleStopThumbLink(e){
    e.preventDefault();
  }

  function hide(el) {
    el?.classList.add("hidden");
  }

  function show(el) {
    el?.classList.remove("hidden");
  }

  function next() {
    gotoDebounced(activeSlideIndex + 1);
  }

  function prev() {
    gotoDebounced(activeSlideIndex - 1);
  }

  function goto(slideIndex) {
    if (!lockSlider) return;
    
    activeSlideIndex = handleIndex(slideIndex);
    handlePaginationItems();
    handleButtons();
    handleSliderItems();
    isLazy && handleLazyLoading();
    handleThumbnailsItems();
    stopLoop();
  }

  function startLoop() {
    if (!autoplay || delay <= 0) {
      return;
    }
    stopLoop();
    loopInterval = setInterval(next, delay);
  }

  function stopLoop() {
    clearInterval(loopInterval);
  }

  setup();

  return {
    carousel,
    next,
    prev,
    start() {
      startLoop();
    },
    stop() {
      stopLoop();
    },
    activeSlide() {
      return activeSlideIndex;
    },
    goto(slideIndex) {
      goto(slideIndex);
    },
    destroy() {
      unbind();
      root.remove();
      emitter.emit("destroy");
    },
    on(name, fn) {
      emitter.on(name, fn);
    },
    root,
  };
}

// const slider = Slider({
//   root: document.querySelector("#slider"),
//   // autoplay: true,
//   loop: true,
//   delay: 1500,
//   speed: 200,
//   thumbnails: true,
//   pagination: false,
//   // startSlide: 0,
//   // controls: false,
//   // pagination: false,
// });