import { getAxios } from "@other/axios";
import { makeSearch } from "@components/search";

class Header {
  constructor() {
    this.header = document.querySelector("header");
    this.headerMiddle = document.querySelector(".header__middle");

    if (!this.header) {
      return;
    }

    this.form = this.header.querySelector("form");
    this.catalog = document.querySelector(".sidebar-catalog");
    this.catalogBtn = this.header.querySelector(".catalog__btn");
    this.searchInput = this.header.querySelector("form input");
    this.catalogMenuItemsMarkup = "";
    this.catalogMenuTimer = null;

    this.bindEvents();
    this.loadCatalogMenu();
    this.makeSearchHandler();
  }

  async loadCatalogMenu() {
    let catalog_menu;
    let catalog_list = document.querySelector(
      ".catalog__content > .catalog__list"
    );
    if (!catalog_menu || catalog_menu === "undefined") {
      if (location.pathname == "/") {
        catalog_menu = catalog_list.outerHTML;
      } else {
        const axios = await getAxios();
        try {
          const res = await axios.default({
            method: "get",
            url: "/netcat/partial.php",
            params: { template: "2", json: 1, partial: "catalog_menu" },
          });
          catalog_menu = res.data.catalog_menu;
        } catch (error) {
          console.log("[header]:", error);
        }
      }
    }

    this.catalogMenuItemsMarkup = catalog_menu;
  }

  bindEvents() {
    document.body.onclick = this.onClick.bind(this);
    this.header.oninput = this.onInput.bind(this);
    document.addEventListener("click", (event) => {
      if (event.target.closest(".search__form")) {
        this.onHeaderInputFocus();
      } else {
        this.onHeaderInputFocusout();
      }
    });
    $(document).on("clearHeaderSearchInput", () => {
      const search = document.querySelector("#search_bar");
      search.value = "";
      search.dispatchEvent(new Event("input"));
      search.closest("form").classList.remove("is-active");
    });
  }

  makeSearchHandler() {
    makeSearch({
      root: ".form__wrapper",
      form: ".search__form",
      url: "/catalog/?isNaked=1&nc_ctpl=2030&find=%QUERY",
      onSubmit: (result) => {
        const root = document.querySelector(".search__form");

        if (result) {
          root.classList.remove("is-bad");
          window.location = `/catalog/${result.length ? "?find=" + encodeURIComponent(result) : ""
            }`;
        } else {
          root.classList.add("is-bad");
        }
      },
      onUpdate: (root) => { },
      onClear: () => { },
    });
  }

  addClassToElementsWithDropdown() {
    const els = this.catalog.querySelectorAll(".catalog__list--child");
    els.forEach((el) => {
      const item = el.closest(".catalog__item");
      item.classList.add("has-child");
      item.setAttribute("data-action", "toggleMenu");
    });
    
  }

  addAdditionalLinks() {
    const linksWithSubmenu = this.catalog?.querySelectorAll(
      ".catalog__item.has-child"
    );

    linksWithSubmenu?.forEach((item) => {
      if (!item.querySelector(".catalog__item--all")) {
        const link = item.querySelector(".catalog__title");
        const div = document.createElement("div");
        const a = document.createElement("a");

        div.classList.add("catalog__item");
        div.classList.add("catalog__item--all");
        a.classList.add("catalog__title");

        a.href = link.href;
        a.innerText = "Все категории";
        div.appendChild(a);

        item.querySelector(".catalog__list-wrapper").prepend(div);
      }
    });
  }

  async openCatalog(event) {
    event.preventDefault();
    if (!this.catalogMenuItemsMarkup) {
      return;
    }
    let catalog_list = document.querySelector(".catalog__list");
    if (!catalog_list) {
      const catalogElement = document.createElement("div");
      catalogElement.innerHTML = this.catalogMenuItemsMarkup;

      this.catalog.querySelector(".catalog__content").append(catalogElement.firstChild);
      this.addClassToElementsWithDropdown();
    }

    const { createMenuAim, changeCloseMenuOffset } = await import("./header.functions");
    createMenuAim();
    this.changeCloseMenuOffset = changeCloseMenuOffset;

    clearTimeout(this.catalogMenuTimer);
    setTimeout(() => {
      this.catalog.classList.add("active");
      this.changeCloseMenuOffset();
    }, 10);

  }

  closeCatalog() {
    this.catalog.classList.remove("active");
    this.clearCatalogActiveClasses();
    this.catalogMenuTimer = setTimeout(() => {
      document.querySelector(".catalog__list")?.remove();
    }, 2000);
  }

  toggleMenu(e) {
    const parent = e.target.parentNode.classList.contains("has-child")
      ? e.target.parentNode
      : e.target.parentNode.parentNode;
    if (
      !parent.classList.contains("has-child") ||
      this.getDocumentSize() > 989
    ) {
      return;
    }

    e.preventDefault();
    parent.classList.toggle("active");
  }

  clearInput(e) {
    this.searchInput.value = "";
    this.setSearchBtn(false);
  }

  clearCatalogActiveClasses() {
    this.catalog.querySelectorAll(".catalog__item.active").forEach((item) => {
      item.classList.remove("active");
    });
  }

  input(e) {
    const value = e.target.value.trim();
    this.setSearchBtn(!!value);
  }

  setSearchBtn(filled) {
    if (filled) this.form.classList.add("is-active");
    else this.form.classList.remove("is-active");
  }

  getDocumentSize() {
    return document.body.offsetWidth;
  }

  onHeaderInputFocus() {
    this.headerMiddle.classList.add("is-open");
  }

  onHeaderInputFocusout() {
    setTimeout(() => {
      if (this.searchInput !== document.activeElement)
        this.headerMiddle.classList.remove("is-open");
    }, 200);
  }

  onClick(e) {
    let action = (e.target.closest("[data-action]") || e.target).dataset.action;
    if (action) {
      this[action] && this[action](e);
    }
  }

  onInput(e) {
    let action = (e.target.closest("[data-action]") || e.target).dataset.action;
    if (action) {
      this[action] && this[action](e);
    }
  }
}

new Header();