import "@js/polyfills";
import "@parts/layout/header";
import "@parts/init.lazysizes";
import "@parts/disable-animations";
import "@parts/layout/popup-banner";
import "@parts/layout/modals.listeners";
import { isMobile } from "./other/helper";
import { TextfieldAnimated } from "@/js/components/text-field";
import "@css/main";
import "@/js/parts/sentry";

TextfieldAnimated();

if (isMobile()) {
  $("body").addClass("is-mobile");
}

// Отложим все, что не критично для первого экрана
window.addEventListener("load", (e) => {
  setTimeout(() => {
    requestIdleCallback(() => {
      import(/* webpackChunkName: "not_critical" */ "./parts/not_critical");
    });
  }, 200);
});